import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings';
import state    from './modules/state';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
        settings,
        state
	}
});