<template>
    <section class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
		<div class="fixed inset-0 transition-opacity">
			<div class="absolute inset-0 bg-gray-500 opacity-75"></div>
		</div>
		<div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full ttz-30" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
				<p class="text-sm text-gray-600 flex items-center justify-center">
						<i class="fas fa-exclamation-triangle violet" v-if="showErrorIcon"></i>
						<span class="text-sm text-gray-600" v-html="message"></span>
					</p>
					<div class="text-sm text-gray-600 flex items-center justify-center mt-4">
						<spinner v-if="showSpinner"/>
					</div>
					<div class="flex justify-center" v-if="allowCancel">
						<button class="bg-purple-500 py-3 px-4 rounded mt-5 text-white" @click.prevent="cancel">
							Cancel
						</button>
					</div>
					<div class="flex justify-center" v-if="allowOK">
						<button class="bg-purple-500 py-3 px-4 rounded mt-5 text-white" @click.prevent="ok">
							Ok
						</button>
					</div>
			</div>
		</div>
    </section>
   
</template>

<script>

import Spinner from './Spinner.vue';

export default {
    props: {
        message:            String,
        allowCancel:       Boolean,
        allowOK:           Boolean,
        showSpinner:        Boolean,
        showErrorIcon:    Boolean,
    },
    methods: {
        // Handles cancel button event
        cancel() {
            this.$emit('cancelRequest');
        },
        // Handles ok button event
        ok() {
            this.$emit('closeNoResults');
        },
    },
    components: {
        Spinner
    }
}
</script>

<style scoped>
.modal-background {
    background-color: hsla(0, 0%, 100%, 0.85);
}

.modal-dialog {
    border-radius: .25rem;
    border: 1px solid #EEE;
    padding: 2rem;
    background-color: white;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}
</style>