const state = {
    showLoading:    false,
    loadingMessage: 'Loading...'
}

const mutations = {
    'SHOW_LOADING' (state, showLoading) {
		state.showLoading = showLoading;
    },
    'SET_LOADING_MESSAGE' (state, message) {
		state.loadingMessage = message;
    }
}

const actions = {
    showLoading: ({commit}, showLoading) => {
		commit('SHOW_LOADING', showLoading);
    },
    setLoadingMessage: ({commit}, message) => {
		commit('SET_LOADING_MESSAGE', message);
    }
}

const getters = {
    showLoading: state => {
		return state.showLoading;
    },
    loadingMessage: state => {
        return state.loadingMessage;
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}