<template>
    <div id="login">
        <div class="bgtempo"></div>
        <div class="flex justify-center md:items-center md:py-20 min-h-screen">
            <div class="max-w-lg md:mx-auto px-1 w-full">
                <div class="flex flex-wrap items-center items-stretch justify-center mb-4 slide-in-out-wrapper w-full mt-5 lg:mt-0">
                    <div class="bg-white form-container h-full rounded-md shadow-2xl w-full">
                        
                        <transition name="in-out-translate-fade" mode="out-in">

                            <!-- Login -->
                            <section 
                                v-if="currentTab == 'login'" 
                                key="open"
                                class="absolute flex flex-col justify-center md:px-16 py-0 top-0 w-full xs:px-4"
                            >
                                <div class="w-full flex mb-5 md:mb-0">
                                    <div class="inline-block text-gray-70 mr-auto">
                                        <div class="logo-container">
                                            <img :src="logoFull"
                                                class="flex w-32 md:w-40 mr-1" alt="TempoTrip">
                                        </div>
                                    </div>
                                    <div class="inline-block md:text-lg self-center text-blue-500 text-sm">Log In</div>
                                </div>
                                <div class="block" 
                                    v-if="errorMessage != ''"
                                >
                                    <p id="error-message" class="mt-2 text-red-500 text-xs italic text-center">
                                        {{ errorMessage }}
                                    </p>
                                </div>
                                
                                <login-form
                                    @login="loginUser"
                                ></login-form>

                                <button 
                                    id="forgot-password-btn"
                                    class="hover:text-blue-800 text-blue-500 text-xs"
                                    @click="currentTab = 'forgot-password'">
                                    Forgot Password?
                                </button>
                            </section>
                            
                            <!-- Forgot password -->
                            <section 
                                v-else 
                                key="closed"
                                class="absolute flex flex-col justify-center md:px-16 py-0 top-0 w-full xs:px-4"
                            >
                                <div class="w-full flex">
                                    <div class="inline-block text-gray-70 mr-auto">
                                        <div class="logo-container">
                                            <!-- <img src="https://d1eaigfvurgmtc.cloudfront.net/0/0/images/global/V2-logo_full.svg"
                                                class="flex w-32 md:w-40 mr-1" alt="TempoTrip"> -->
                                            <img :src="logoFull"
                                                class="flex w-32 md:w-40 mr-1" alt="TempoTrip">
                                        </div>
                                    </div>
                                    <div  class="inline-block md:text-lg self-center text-blue-500 text-sm">
                                        Forgot Password
                                    </div>
                                </div>
                                <div class="flex flex-col my-3">
                                    <div class="block relative w-full">
                                        <!-- <forgot-password>
                                        </forgot-password> -->
                                        <p class="font-bold p-2 text-center text-purple-500 text-sm" v-if="displayResetError">{{ resetError }}</p>
                                        <p class="font-bold p-2 text-center text-purple-500 text-sm" v-if="displayResetSuccess">{{ resetSuccess }}</p>

                                        <forgot-password-form 
                                            v-if="!displayResetSuccess"
                                            @reset-password="resetPassword"
                                            :disabled="disablePasswordReset"
                                        >
                                        </forgot-password-form>
                                    </div>
                                    <div class="block w-full" v-if="!displayResetSuccess">
                                        <p class="hover:text-blue-800 text-blue-500 text-xs cursor-pointer" 
                                            @click="currentTab = 'login'"
                                        >Back
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </transition>

                    </div>
                </div>
                <div class="absolute p-12 relative text-center w-full">
                    <div class="flex flex-col justify-start h-full md:pt-10">
                        <!-- <div class="text-white w-full text-2xl font-bold mb-2">Not Registered?</div><div class="text-white">Contact your company's department travel administrator to get your account setup.</div> -->
                        <p class="text-center text-gray-200 text-sm">
                            &copy;{{currentYear}} TempoTrip LLC. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios                from 'axios';
import qs                   from 'qs';

import LoginForm            from '@/components/LoginForm.vue';
import ForgotPasswordForm   from "@/components/ForgotPasswordForm.vue";

import Utils                from '@source-classes/Utils';
import Cookie               from '@source-classes/Cookie';

// Login page.  Holds the login form and forgot password link
export default {
    data() {
        return {
            // UI Control
            errorMessage:           '',
            currentTab:             'login',
            disablePasswordReset:   false,
            resetSuccess:           "We have sent an email containing instructions for changing your password.",
            resetError:             "There was a problem with requesting a new password.",
            displayResetSuccess:    false,
            displayResetError:      false,
            currentYear: new Date().getFullYear()
        };
    },
    computed:{
        logoFull() {
                return this.$store.getters.cdn_url + '0/0/img/V2-logo_full.png';  
            }
    },
    methods: {
        loginUser(credentials) {
            // Clear existing error message
            this.errorMessage = '';

            this.$store.dispatch('showLoading', true);
            this.$store.dispatch('setLoadingMessage', "Verifying credentials...");

            // clean username
            const username = credentials.username.toLowerCase().trim();
            
            // Do the login
            axios.post(
                system_output.user_session_data.api_hostname + 'Authentication/get_jwt/',
                qs.stringify({
                    json: JSON.stringify({
                        username,
                        password: credentials.password,
                        jwt_type: "login"
                    }),
                    hostname: system_output.user_session_data.hostname,
                    user_ip: system_output.user_session_data.user_ip
                }), {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded'
                    }
                },
            )
            .then(res => {
                if (res.data.jwt && res.data.jwt != '') {
                    this.$store.dispatch("setLoadingMessage", "Verified! Logging you in...");

                    // Successful login
                    // Set cookies
                    const domain = system_output.user_session_data.domain;
                    //Cookie.setCookie('logintoken', res.data.authenticationLoginToken, 'TOMORROW', domain);
                    Cookie.setCookie('jwt', res.data.jwt, this.$store.getters.cookieExpire, domain);
                    Cookie.setCookie('role_id', res.data.user_role_id, this.$store.getters.cookieExpire,
                        domain);
                    Cookie.setCookie('username', encodeURIComponent(res.data.username), this.$store.getters
                        .cookieExpire, domain);
                    Cookie.setCookie('default_url', res.data.default_site, this.$store.getters.cookieExpire,
                        domain);

                    // Set redirect url
                    const url = window.location.href;
                    const protocol = url.split("/")[0] + "//";
                    if (system_output.queries.rurl) {
                        // Redirect to provided url
                        window.location.replace(protocol + system_output.queries.rurl);
                    } else {
                        // Default redirect
                        window.location.replace(protocol + res.data.default_site);
                    }
                } else if ( 
                        res.data && 
                        res.data.results && 
                        exists_check(res.data.results.error) && 
                        res.data.results.error != ''
                ) {
                    // Failure - hide DB errors
                    if(res.data.results.error.includes("DB Error")) {
                        this.errorMessage = "There was an issue attempting to log in.";
                    } else {
                        this.errorMessage = res.data.results.error;
                    }
                    this.$store.dispatch("showLoading", false);
                } else {
                    // Unknown error
                    this.errorMessage = "There was an issue attempting to log in.";
                    this.$store.dispatch("showLoading", false);
                }
            })
            .catch(e => {
                console.log("Error:", e);
                this.errorMessage = e;
                this.$store.dispatch("showLoading", false);
            });
        },
        resetPassword(email) {
            this.displayResetError = false;
            this.displayResetSuccess = false;
            this.$store.dispatch("showLoading", true);
            this.$store.dispatch("setLoadingMessage", "Resetting password...")
            axios.post(
                system_output.user_session_data.api_hostname + 'Authentication/request_password_reset/', 
                qs.stringify( {
                    json: JSON.stringify({ email }),
                    hostname: system_output.framework_info.frameworks.api.return.site_hostname,
                    user_ip: system_output.user_session_data.user_ip
                }),
                { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
            )
            .then(res => {
                console.log("res:", res);
                this.$store.dispatch("showLoading", false);
                this.displayResetSuccess = true;
                this.disablePasswordReset = true;
            })
            .catch(e => {
                console.log("Error:", e);
                this.$store.dispatch("showLoading", false);
                this.displayResetError = true;
            });
        }
    },
    created() {
        // Get error message
        if (Utils.exists(system_output.error)) {
            this.errorMessage = system_output.error;
        }
        // Set smartpoint cookies
        const domain = system_output.user_session_data.domain;
        if (Utils.exists(system_output.queries.smp)) {
            Cookie.setCookie('smp', system_output.queries.smp, 'TOMORROW', domain);
        }
        if (Utils.exists(system_output.queries.soi)) {
            Cookie.setCookie('soi', system_output.queries.soi, 'TOMORROW', domain);
        }
    },
    components: {
        LoginForm,
        ForgotPasswordForm
    }
}
</script>

<style scoped>
.bgtempo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: -webkit-gradient(linear, left bottom, right top, from(#8a6295), color-stop(#7e6193), color-stop(#726090), color-stop(#665f8d), color-stop(#5b5d88), color-stop(#675f8d), color-stop(#736190), color-stop(#7f6293), color-stop(#a86695), color-stop(#cc6b8c), color-stop(#e8767b), to(#f68a65));
    background-image: -o-linear-gradient(left bottom, #8a6295, #7e6193, #726090, #665f8d, #5b5d88, #675f8d, #736190, #7f6293, #a86695, #cc6b8c, #e8767b, #f68a65);
    background-image: linear-gradient(to right top, #8a6295, #7e6193, #726090, #665f8d, #5b5d88, #675f8d, #736190, #7f6293, #a86695, #cc6b8c, #e8767b, #f68a65);
}

.logo-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* md: */
@media (min-width:768px) {}
    
</style>