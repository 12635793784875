<template>
    <form v-on:submit.prevent id="password-form">
        <div class="flex my-3">
            <div class="block relative w-full">
            <input 
                type="text" 
                name="email" 
                class="username border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                :class="{'input-error': invalid_email}" 
                placeholder="Username | Email address" 
                v-model="email"
            >
            </div>
        </div>
        <div class="flex my-3">
            <div class="block relative w-full">
            <input 
                name="submit" 
                type="submit" 
                id="submit" 
                class="hover:bg-purple-300 hover:text-white focus:outline-none bg-purple-500 font-normal fs-12 outline-none px-10 py-3 rounded text-white w-full"
                value="Reset Password" 
                :disabled="disabled"
                @mouseover="display_error = true" 
                @click="emitResetPassword"
            >
            </div>
        </div>
    </form>
</template>

<script>

    import Validator from '@source-classes/Validator';

    export default {
        props: {
            disabled: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {
                email: '',
                display_error: false,
            };
        },
        computed: {
            invalid_email() {
                return this.display_error && !Validator.isValidEmail(this.email);
            }
        },
        methods: {
            emitResetPassword() {
                if(!this.invalid_email) {
                    this.$emit('reset-password', this.email);
                }                
            }
        }
    }

</script>