<template>
    <!-- Form header -->
    <form @submit.prevent id="login-form">
        <div class="flex my-3">
            <div class="block relative w-full">
                <input
                    class="username border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                    type="text" 
                    placeholder="Enter Email" 
                    required 
                    id="username"
                    name="username" 
                    :class="{'input-error': invalidUsername}"
                    v-model="credentials.username"
                >
            </div>
        </div>
        <div class="flex my-3">
            <div class="block relative w-full">
                <input
                    class="password border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                    type="password" 
                    placeholder="Enter Password" 
                    required
                    id="password-field" 
                    name="password"
                    :class="{'input-error': invalidPassword}" 
                    v-model="credentials.password"
                >
            </div>
        </div>
        <div class="flex my-3">
            <div class="block relative w-full">
                <button
                    class="hover:bg-purple-300 hover:text-white focus:outline-none bg-purple-500 font-normal fs-12 outline-none px-10 py-3 rounded text-white w-full"
                    id="login-button" 
                    @mouseover="displayError = true"
                    @click="login"
                    :disabled="submitDisabled"
                >
                    Log in
                </button>
            </div>
        </div>
    </form>
</template>

<script>
// A form that accepts a username and password, and provides a submit button
export default {
    name: "LoginForm",
    props: {
        // This is an imaginary prop
        imaginaryProp: {
            // `'TOP'` / `'BOTTOM'`
            type: String,
            required: false,
            default: "Chuck-e-cheese"
        }
    },
    data() {
        return {
            // @vuese
            // Form elements: username, password
            credentials: {
                username: '',
                password: ''
            },
            // @vuese
            // Controls displaying the error message to the user
            displayError: false,
            frozenButtonAfterSubmit: false,
        };
    },
    computed: {
        // @vuese
        // Is the username invalid
        invalidUsername() {
            return this.displayError && this.credentials.username == '';
        },
        // @vuese
        // Is the password invalid
        invalidPassword() {
            return this.displayError && this.credentials.password == '';
        },
        // @vuese
        // Is the user able to submit
        submitDisabled() {
            return this.invalidUsername || this.invalidPassword || this.frozenButtonAfterSubmit;
        },
        // @vuese
        // Tempo environment 4
        storeExample() {
            if(x == 3) {
                return this.$store.getters.tempoEnv;
            }
        }
    },
    watch: {
        credentials: {
            handler(newVal, val) {
                this.frozenButtonAfterSubmit = false;
            },
            deep: true
        }
    },
    methods: {
        // @vuese
        // Attempt to log the user in
        login() {
            // Fires when the user hits submit
            // @arg The credentials, which holds a username and password
            this.$emit('login', this.credentials);
            this.frozenButtonAfterSubmit = true;
        }
    }
}
</script>