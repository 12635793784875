<template>
    <div id="change-password">
        <!-- <div class="bgtempo" v-bind:class="{ bgtempo2: changeGradient, bgtempo: !changeGradient }"></div> -->
        <div class="bgtempo"></div>
        <div class="flex justify-center md:items-center md:py-20 min-h-screen">
            <div class="max-w-lg md:mx-auto px-1 w-full">
                <div class="flex flex-wrap items-center items-stretch justify-center mb-4 slide-in-out-wrapper w-full">
                    <div class="bg-white form-container h-full rounded-md shadow-2xl w-full">
                        <section class="absolute flex flex-col justify-center md:px-16 py-0 top-0 w-full xs:px-4">
                                <div class="w-full flex mb-5 md:mb-3">
                                    <div class="inline-block text-gray-70 mr-auto">
                                        <div class="logo-container">
                                            <img src="https://d1eaigfvurgmtc.cloudfront.net/0/0/images/global/V2-logo_full.svg"
                                                class="flex w-32 md:w-40 mr-1" alt="TempoTrip">
                                        </div>
                                    </div>
                                    <div class="inline-block md:text-lg self-center text-blue-500 text-sm">Change Password</div>
                                </div>
                                <template v-if="!displaySuccess">
                                    <p class="text-center text-sm mb-2">You have 24 hours to change your password</p>
                                    <p class="text-center text-sm" id="password-info">Password must be 8 characters and contain at least one letter, one number, and one <abbr title="#?!@$%^&amp;*- " class="" style="border-bottom:  1px solid black;"><u>special character</u></abbr>.</p>
                                </template>
                                <p class="text-white bg-red-500 font-bold text-center rounded-md text-sm my-2 p-2" v-if="displayError">{{ error }}</p>
                                <p class="text-purple-500 font-bold text-center text-sm my-2 p-2" v-if="displaySuccess">{{ success }}</p>
                                <div class="block w-full" v-if="displaySuccess">
                                    <p class="hover:text-purple-800 text-purple-500 text-base text-center font-bold cursor-pointer" 
                                        @click="$router.push('/')"
                                    >Go to Login
                                    </p>
                                </div>

                                <change-password-form
                                    v-if="!displaySuccess"
                                    @change-password="changePassword"
                                ></change-password-form>

                                
                        </section>
                    </div>
                </div>
                <div class="absolute p-12 relative text-center w-full">
                    <div class="flex flex-col justify-start h-full md:pt-10">
                        <p class="text-center text-gray-200 text-sm">
                            &copy;{{currentYear}} TempoTrip LLC. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios                from 'axios';
import qs                   from 'qs';

import ChangePasswordForm   from '@/components/ChangePasswordForm.vue';

import Cookie               from '@source-classes/Cookie';

export default {
    props: {
        reset_token: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            error:              'There was an error while attempting to update your password.',
            success:            'Your password has been updated. Please login.',
            displayError:       false,
            displaySuccess:     false,
            currentYear: new Date().getFullYear()
        };
    },
    methods: {
        changePassword(password) {
            this.$store.dispatch('showLoading', true);
            this.$store.dispatch('Changing password...');
            axios.post(
                system_output.user_session_data.api_hostname + 'Authentication/Authenticate/update_password', 
                qs.stringify( { 
                    json: JSON.stringify({ reset_token: this.reset_token, password }),
                    token: Cookie.getCookie('otapi_token'),
                    hostname: system_output.framework_info.frameworks.api.return.site_hostname,
                    user_ip: system_output.user_session_data.user_ip
                } ),
                { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
            )
            .then(res => {
                this.$store.dispatch('showLoading', false);
                if(res.data && res.data.results && res.data.results.error) {
                    this.displayError = true;
                } else {
                    this.displaySuccess = true;
                }
                
                console.log("res:", res);
            })
            .catch(e => {
                this.$store.dispatch('showLoading', false);
                console.log("Error:", e);
                alert("There was a malfunction. Please try again shortly.");
            });
        }
    },
    components: {
        ChangePasswordForm
    },
}
</script>

<style scoped>
.bgtempo {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-image: -webkit-gradient(linear, left bottom, right top, from(#8a6295), color-stop(#7e6193), color-stop(#726090), color-stop(#665f8d), color-stop(#5b5d88), color-stop(#675f8d), color-stop(#736190), color-stop(#7f6293), color-stop(#a86695), color-stop(#cc6b8c), color-stop(#e8767b), to(#f68a65));
        background-image: -o-linear-gradient(left bottom, #8a6295, #7e6193, #726090, #665f8d, #5b5d88, #675f8d, #736190, #7f6293, #a86695, #cc6b8c, #e8767b, #f68a65);
        background-image: linear-gradient(to right top, #8a6295, #7e6193, #726090, #665f8d, #5b5d88, #675f8d, #736190, #7f6293, #a86695, #cc6b8c, #e8767b, #f68a65);
    }
    .logo-container {
        @apply flex items-center;
    }
    .slide-in-out-wrapper {
  position: relative;
  height: 450px;
}
.slide-in-out-wrapper .form-container {
  position: absolute;
  top:0px;
}
.slide-in-out-wrapper .form-container section {
    height:100%
}
@media only screen and (max-width: 760px) {
  #password-change {
      width: 100%;
  }
  .slide-in-out-wrapper .form-container {
  position: absolute;
  top:50px;
}
}
</style>