class Utils {

	static objectToArray(obj) {
		// Get this non-object nonsense out of here
		if(typeof obj != "object") {
			return;
		}

		let arr = [];
		for(var key in obj) {
			if(obj.hasOwnProperty(key)) {
                arr.push(obj[key])
			}
		}

		return arr;
	}

	static exists(data) {
		if (typeof data !== 'undefined' && data !== null && data !== '' && data !== false) {
			return true;
		} else {
			return false;
		}
    }

    static cleanNumeric(str) {
        return str.trim().replace(/[^0-9.]/g, '')
    }
    
    static cleanAlphaNumeric(str) {  
        return str.trim().replace(/[^a-zA-Z0-9 ]/g, '');
    }

    static cleanAddress(str) {  
        return str.trim().replace(/[^a-zA-Z0-9,.:#&* ]/g, '');
    }
    
    static cleanEmail(str) {
        return str.trim().replace(/[^a-zA-Z0-9.@_\-]/g, '')
    }
	
	static objectEquals(obj1, obj2) {
		// cannot handle nested objects
		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (let k of keys1) {
			if (obj1[k] !== obj2[k]) {
				return false;
			}
		}

		return true;
    }
    
    // static deepClone(obj) {
    //     if(typeof obj == "object")
    //     let newObj = {...obj};
    //     if()
    // }
}

export default Utils;