<template>
    <div id="password-page">
        <p class="errors" v-if="display_error">{{ error }}</p>
        <p class="success" v-if="display_success">{{ success }}</p>

        {{ display_success }}
        <forgot-password-form 
            v-if="!display_success" 
            @reset-password="resetPassword" 
            :disabled="disabled"
        >
        </forgot-password-form>
        <transition name="fade">
            <div v-if="display_modal">
                <confirm-modal message="Sending instructions..." show-spinner />
            </div>
        </transition>
    </div>
</template>

<script>

    import axios                from 'axios';
    import qs                   from 'qs';

    import ForgotPasswordForm   from '@source-components/login/ForgotPasswordForm.vue';
    import ConfirmModal         from '@source-components/utils/ConfirmModal.vue';

    import Cookie               from '@source-classes/Cookie';

    export default {
        components: {
            ForgotPasswordForm,
            ConfirmModal
        },
        data() {
            return {
                error: "There was a problem with requesting a new password",
                success: "We have sent an email containing instructions for changing your password.",
                display_error: false,
                display_success: false,
                display_modal: false,
                disabled: false,
            };
        },
        methods: {
            resetPassword(email) {
                console.log("Requesting password for", email);
                this.display_error = false;
                this.display_success = false;
                this.display_modal = true;
                axios.post(
                    system_output.user_session_data.api_hostname + 'Authentication/request_password_reset/', 
                    qs.stringify( {
                        json: JSON.stringify({ email }),
                        token: Cookie.getCookie('otapi_token'),
                        hostname: system_output.framework_info.frameworks.api.return.site_hostname,
                        user_ip: system_output.user_session_data.user_ip
                    }),
                    { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
                )
                .then(res => {
                    this.display_modal = false;
                    console.log("res:", res);
                    this.display_success = true;
                    this.disabled = true;
                })
                .catch(e => {
                    this.display_modal = false;
                    this.display_error = true;
                    console.log("Error:", e);
                });

            }
        }
    }

    </script>