import Vue		from 'vue';

const state = {
    tempoEnv: '',
	cdn_url: system_output.user_session_data.cdn_url
};

const mutations = {
	'SET_TEMPO_ENV' (state) {
        Vue.set(state, 'tempoEnv', system_output.framework_info.frameworks.api.return.environment)
	},
};

const actions = {
	setTempoEnv: ({commit}) => {
		commit('SET_TEMPO_ENV');
	},
};

const getters = {
	tempoEnv: state => {
		return state.tempoEnv;
	},
	cdn_url: state => {
        return state.cdn_url;
	},
	cookieExpire: state => {
        let d = new Date();
		if (state.tempoEnv === 'prod') {
            // prod jwt expires in 1hr, set cookie for 59m 59s
            d.setTime(d.getTime() + (60 * 59 * 1000));
		} else if (state.tempoEnv === 'qa') {
            // jwt expires in 1d, set cookie for 23h 59m 59s
			d.setTime(d.getTime() + (24 * 60 * 59 * 1000));
		} else {
            // jwt expires in 1d, set cookie for 23h 59m 59s
            d.setTime(d.getTime() + (24 * 60 * 59 * 1000));
        }
        let expire = d.toUTCString()
		return expire;
	},
};

export default {
	state,
	mutations,
	actions,
	getters
}