<template>
    <section class="ttfixed ttbottom-0 ttinset-x-0 ttpx-4 ttpb-4 sm:ttinset-0 sm:ttflex sm:ttitems-center sm:ttjustify-center ttconfirm-modal">
		<div class="ttfixed ttinset-0 tttransition-opacity">
			<div class="ttabsolute ttinset-0 ttbg-gray-500 ttopacity-75"></div>
		</div>
		<div class="ttbg-white ttrounded-lg ttoverflow-hidden ttshadow-xl tttransform tttransition-all sm:ttmax-w-lg sm:ttw-full ttz-30" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<div class="ttbg-white ttpx-4 ttpt-5 ttpb-4 sm:ttp-6 sm:ttpb-4">
				<p class="tttext-sm tttext-gray-600 ttflex ttitems-center ttjustify-center">
                    <i class="fas fa-exclamation-triangle violet" v-if="showErrorIcon"></i>
                    <span class="tttext-sm tttext-gray-600" v-html="message">
                        Confirming Price Availability......
                    </span>
                </p>
                <div class="tttext-sm tttext-gray-600 ttflex ttitems-center ttjustify-center ttmt-4">
                    <tt-spinner v-if="showSpinner"/>
                </div>
                <div class="ttflex ttjustify-center">
                    <div class="" v-if="allowCancel">
                        <button class="ttbg-purple-500 ttpy-3 ttpx-4 ttrounded ttmt-5 tttext-white" @click.prevent="cancel">
                            {{ cancelText }}
                        </button>
                    </div>
                    <div class="ttml-5" v-if="allowOK">
                        <button class="ttbg-purple-500 ttpy-3 ttpx-4 ttrounded ttmt-5 tttext-white" @click.prevent="ok">
                            {{ confirmText }}
                        </button>
                    </div>
                </div>
			</div>
		</div>
    </section>
   
</template>

<script>

    import Spinner from '@source-components/utils/Spinner';

    export default {
        data() {
            return {
                rotateMsg: []
            }
        },
        components: {
            ttSpinner: Spinner
        },
        props: {
            message:            String,
            allowCancel:        Boolean,
            allowOK:            Boolean,
            showSpinner:        Boolean,
            showErrorIcon:      Boolean,
            confirmText: {
                type: String,
                required: false,
                default: "OK"
            },
            cancelText: {
                type: String,
                required: false,
                default: "Cancel"
            }
        },
        methods: {
            // Handles cancel button event
            cancel() {
                this.$emit('cancel');
            },
            // Handles ok button event
            ok() {
                this.$emit('ok');
            },
            // Random Message Caption
            cycleMessage() {  
                let maxMsg = this.rotateMsg.length;
                this.message = this.rotateMsg[ this.rotateMsg.currentMsg++ ];
                if( this.rotateMsg.currentMsg === maxMsg ){
                    this.rotateMsg.currentMsg = 0;
                    clearTimeout(this.rotateMsg.intervalMsg);
                }
            }
        },
        created() {
            // More than one message
            if( this.message && this.message.indexOf("|") !== -1 ){    
                this.rotateMsg = this.message.split("|");
                this.rotateMsg.currentMsg = 0;
                this.cycleMessage();
                this.rotateMsg.intervalMsg = setInterval(() => this.cycleMessage(), 2400);
            }
        }
    }
    
</script>
<style scoped>
    .modal-background {
        background-color: hsla(0, 0%, 100%, 0.85);
        /* z-index: 9999;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        position: fixed;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center; */
    }

    .modal-dialog {
        border-radius: .25rem;
        border: 1px solid #EEE;
        padding: 2rem;
        background-color: white;
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 500px;
            margin: 1.75rem auto;
        }
    }

    .ttabsolute{position:absolute}
    .ttbg-gray-500{background-color: #a0aec0}
    .ttbg-purple-500{background-color: #8a6295}
    .ttbg-white{background-color: #ffffff}
    .ttbottom-0{bottom:0}
    .ttfixed{position:fixed}
    .ttflex {display:flex}
    .ttinset-0 {top:0;right:0;bottom:0;left:0}
    .ttinset-x-0{right:0;left:0}
    .ttitems-center{align-items: center}
    .ttjustify-center {justify-content:center}
    .ttmt-4{margin-top: 1rem}
    .ttmt-5{margin-top:1.25rem}
    .ttopacity-75{opacity:.75}
    .ttoverflow-hidden{overflow:hidden}
    .ttpb-4{padding-bottom: 1rem}
    .ttpt-5{padding-top: 1.25rem}
    .ttpx-4{padding-left:1rem;padding-right:1rem}
    .ttpy-3{padding-top:.75rem;padding-bottom:.75rem}
    .ttrounded{border-radius:.25rem}
    .ttrounded-md{border-radius:.375rem}
    .ttrounded-lg{border-radius:.5rem}
    .ttshadow-xl{box-shadow:0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)}
    .tttext-gray-600 {color:#718096}
    .tttext-sm {font-size:.875rem}
    .tttext-white{color: #fff}
    .tttransform{--transform-translate-x:0;--transform-translate-y:0;--transform-rotate:0;--transform-skew-x:0;--transform-skew-y:0;--transform-scale-x:1;--transform-scale-y:1;transform:translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))}
    .tttransition-all{transition-property:all}
    .tttransition-opacity{transition-property:opacity}
    .ttz-20{z-index:20}
    .ttz-30{z-index:30}
    @media (min-width:640px) {
        .sm\:ttflex{display:flex}
        .sm\:ttinset-0{top:0;right:0;bottom:0;left:0}
        .sm\:ttitems-center{align-items:center}
        .sm\:ttjustify-center{justify-content:center}
        .sm\:ttmax-w-lg{max-width:32rem}
        .sm\:ttp-6{padding:1.5rem}
        .sm\:ttpb-4{padding-bottom:1rem}
        .sm\:ttw-full{width:100%}
    }
    .ttconfirm-modal{
    z-index: 99999999!important}
    .ttz-70 {
    z-index: 70!important;
}

</style>