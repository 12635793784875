<template>
    <div id="affiliate-signup">
        <div class="bgtempo"></div>
            <div class="max-w-6xl mx-auto py-6 sm:px-6 lg:x-8 min-h-screen">
                <div class="px-4 py-6 sm:px-0">
                    <div class="flex flex-col my-3">
                        <div class="block relative w-full">
                            <div class="errors" v-if="displayError">{{ error }}</div>
                            <div class="success" v-if="displaySuccess">{{ success }}</div>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <section class="lg:flex-1 bg-white border border-gray-300 my-5 rounded-lg w-full leading-tight">
                            <affiliate-signup-form @save-affiliate="saveAffiliate"></affiliate-signup-form>
                        </section>
                    </div>
                </div>
                <div class="flex flex-col justify-start h-full md:pt-10">
                    <p class="text-center text-gray-200 text-sm">
                        &copy;{{currentYear}} TempoTrip LLC. All rights reserved.
                    </p>
                </div>
            </div>
        <div>
    </div>
</template>

<script>

    import axios                from 'axios';
    import qs                   from 'qs';

    import AffiliateSignupForm  from '@/components/AffiliateSignupForm.vue';

    import Utils                from '@source-classes/Utils';
    import Cookie               from '@source-classes/Cookie';

    export default {
        data() {
            return {
                error:              '',
                success:            'You have successfully signed up! Please check your email to continue.',
                displayError:      false,
                displaySuccess:    false,
                jwt:                '',
                currentYear: new Date().getFullYear()
            }
        },
        methods: {
            getPublicJWT() {
                axios.post(
                    system_output.user_session_data.api_hostname + 'Authentication/get_jwt',
                    qs.stringify({ 
                        json: JSON.stringify({ 
                            'jwt_type': 'public'
                         }),
                        hostname: system_output.framework_info.frameworks.api.return.site_hostname,  
                        user_ip: system_output.user_session_data.user_ip
                    }),
                )
                .then( res =>{
                    if(res.data && res.data.jwt && res.data.jwt != '') {
                        // set jwt, do not use a cookie here.  We don't need this user to go anywhere else
                        this.jwt = res.data.jwt;
                    }
                })
                .catch( e => console.log("Error:", e) );
            },
            saveAffiliate(aff) {
                this.displayError = false;
                this.displaySuccess = false;
                this.$store.dispatch('showLoading', true);

                let affiliate = { ...aff }; // Need a new object to manipulate

                // Add some extra default assignments
                affiliate.site_type_id = 0;
                affiliate.brandable = 1;

                // Clean up a bit...
                affiliate.co_name           = Utils.cleanAlphaNumeric(affiliate.co_name);
                affiliate.f_name            = Utils.cleanAlphaNumeric(affiliate.f_name);
                affiliate.l_name            = Utils.cleanAlphaNumeric(affiliate.l_name);
                affiliate.existing_bar      = Utils.cleanAlphaNumeric(affiliate.existing_bar);
                affiliate.itinerary_id      = Utils.cleanAlphaNumeric(affiliate.itinerary_id);
                affiliate.air_service_fee   = Utils.cleanNumeric(affiliate.air_service_fee);
                affiliate.car_service_fee   = Utils.cleanNumeric(affiliate.car_service_fee);

                // Default backoffice_id
                affiliate.backoffice_id = affiliate.existing_bar != '' ? affiliate.existing_bar : 0;
                delete affiliate.existing_bar;

                // Formatting air rewards numbers
                if(affiliate.air_corporate_rewards != '') {
                    if(affiliate.air_corporate_rewards.indexOf(',') < 0) {
                        affiliate.air_corporate_rewards += ',';
                    }
                    const rewards_list = affiliate.air_corporate_rewards.split(',');
                    let corporate_rewards = {};
                    rewards_list.forEach(rewards => {
                        const rewards_parts = rewards.split(':');
                        corporate_rewards[Utils.cleanAlphaNumeric( rewards_parts[0] )] = Utils.cleanAlphaNumeric( rewards_parts[1] );
                    });
                    affiliate.air_corporate_rewards = corporate_rewards;
                }

                // Formatting car rewards numbers
                if(affiliate.car_corporate_rewards != '') {
                    if(affiliate.car_corporate_rewards.indexOf(',') < 0) {
                        affiliate.car_corporate_rewards += ',';
                    }
                    const rewards_list = affiliate.car_corporate_rewards.split(',');
                    let corporate_rewards = {};
                    rewards_list.forEach(rewards => {
                        const rewards_parts = rewards.split(':');
                        corporate_rewards[Utils.cleanAlphaNumeric( rewards_parts[0] )] = Utils.cleanAlphaNumeric( rewards_parts[1] );
                    });
                    affiliate.car_corporate_rewards = corporate_rewards;
                }

                

                axios.post(
                    system_output.user_session_data.api_hostname + 'Affiliate/save_affiliate',
                    qs.stringify({ 
                        json: JSON.stringify({ ...affiliate }),
                        //token: Cookie.getCookie('otapi_token'),
                        hostname: system_output.user_session_data.hostname,  
                        user_ip: system_output.user_session_data.user_ip     
                    }),
                    { headers: { 
                        'content-type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${this.jwt}`
                    } },
                )
                .then( res =>{
                    this.$store.dispatch('showLoading', false);
                    window.scrollTo(0,0);
                    console.log("Res:", res);
                    if(res.data.results && res.data.results.error && res.data.results.error != '') {
                        this.error = res.data.results.error;
                        this.displayError = true;
                    } else {
                        this.displaySuccess = true;
                    }
                    // res.data.results.error
                })
                .catch( e => {
                    console.log("Error:", e);
                    this.error = e;
                    this.$store.dispatch('showLoading', true);
                } );
            }
        },
        created() {
            this.getPublicJWT();
        },
        components: {
            AffiliateSignupForm
        }
    }

</script>
<style scoped>
.bgtempo {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-image: -webkit-gradient(linear, left bottom, right top, from(#8a6295), color-stop(#7e6193), color-stop(#726090), color-stop(#665f8d), color-stop(#5b5d88), color-stop(#675f8d), color-stop(#736190), color-stop(#7f6293), color-stop(#a86695), color-stop(#cc6b8c), color-stop(#e8767b), to(#f68a65));
        background-image: -o-linear-gradient(left bottom, #8a6295, #7e6193, #726090, #665f8d, #5b5d88, #675f8d, #736190, #7f6293, #a86695, #cc6b8c, #e8767b, #f68a65);
        background-image: linear-gradient(to right top, #8a6295, #7e6193, #726090, #665f8d, #5b5d88, #675f8d, #736190, #7f6293, #a86695, #cc6b8c, #e8767b, #f68a65);
    }
    .logo-container {
        @apply flex items-center;
    }
    .slide-in-out-wrapper {
  position: relative;
  height: 960px;
}
.slide-in-out-wrapper .form-container {
  position: absolute;
  top:0px;
}
.slide-in-out-wrapper .form-container section {
    height:100%
}
@media only screen and (max-width: 760px) {
  #password-change {
      width: 100%;
  }
  .slide-in-out-wrapper .form-container {
  position: absolute;
  top:50px;
}
}
</style>