<template>
  <div id="app">
    <outdated-browser v-if="isIE"></outdated-browser>
    <template v-if="!isIE">
        <transition name="slide" mode="out-in">
            <router-view/>
        </transition>

        <transition name="fade">
            <div v-if="showLoading">
                <loading-modal :message="loadingMessage" show-spinner />
            </div>
        </transition>
    </template>
  </div>
</template>

<script>
// Description of the component
import OutdatedBrowser from '@source-components/utils/OutdatedBrowser.vue';
import LoadingModal from '@/components/LoadingModal.vue';

export default {
  name: 'login',
  computed: {
      // Used to turn the "loading modal" on and off
      showLoading() {
          return this.$store.getters.showLoading;
      },
      // Holds the contents of what is displayed on the loading modal
      loadingMessage() {
          return this.$store.getters.loadingMessage;
      },
      isIE() {
            let isIE = false;
            const ua = window.navigator.userAgent;
            // Check for 10 or below
            const msie = ua.indexOf("MSIE ");
            isIE = msie > 0;
            // Check for 11
            const trident = ua.indexOf('Trident/');
            isIE = isIE || trident > 0;
            return isIE;
      }
  },
  created() {
    this.$store.dispatch('setTempoEnv');
  },
  components: {
      OutdatedBrowser,
      LoadingModal
  }
}
</script>

<style>
@import url("https://use.typekit.net/nsa0fui.css");
#app {
    font-family: "proxima-nova",sans-serif;
    /* font-weight: 100;
    font-style: normal; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #eeeeee
}

.slide-enter-active {
    animation: slide-in 200ms ease-out forwards;
}
.slide-leave-active {
    animation: slide-out 200ms ease-out forwards;
}

@keyframes slide-in {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0px);
        opacity: 1;
    }
    to {
        transform: translateY(-30px);
        opacity: 0;
    }
}

.in-out-translate-fade-enter {
    transform: translateX(-100%);
}

.in-out-translate-fade-enter-to {
    transform: translateX(0%);
}

.in-out-translate-fade-leave {
    transform: translateX(0%);
}

.in-out-translate-fade-leave-to {
    transform: translateX(-100%);
}

.in-out-translate-fade-enter-active,
.in-out-translate-fade-leave-active {
    transition: all 200ms
}

.in-out-translate-fade-enter,
.in-out-translate-fade-leave-active {
    opacity: 0;
}
.slide-in-out-wrapper {
    position: relative;
    height: 400px;
}
.slide-in-out-wrapper .form-container {
    position: absolute;
    top:0;
}
.slide-in-out-wrapper .form-container section {
    height:100%
}

.input-error {
    -webkit-box-shadow: 0 0 6px #f56565;
    box-shadow: 0 0 6px #f56565;
}


</style>