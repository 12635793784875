import Vue              from 'vue'
import VueRouter        from 'vue-router'

import Login            from '../components/pages/Login.vue';
import ForgotPassword   from '../components/pages/ForgotPassword.vue';
import ChangePassword   from '../components/pages/ChangePassword.vue';
import AffiliateSignup  from '../components/pages/AffiliateSignup.vue';
import AutoApprove      from '../components/pages/AutoApprove.vue';
import PendingCheck      from '../components/pages/PendingCheck.vue';
import Redirect         from '../components/pages/Redirect.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword
    },
    {
        path: '/change-password/:reset_token',
        name: 'change-password',
        component: ChangePassword,
        props: true
    },
    {
        path: '/affiliate-signup',
        name: 'affiliate-signup',
        component: AffiliateSignup
    },
    {
        path: '/autoapprove',
        name: 'autoapprove',
        component: AutoApprove
    },
    {
        path: '/pendingcheck',
        name: 'pendingcheck',
        component: PendingCheck
    },
    {
        path: '*',
        component: Redirect
    }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
