class Validator {

    static isAlphaNumeric(str) {
        if(!str) {
            return;
        }
        return str == str.match("^[a-zA-Z0-9]+$");
    }
	static isValidEmail(email) {
		if(!email) {
			return false;
		}
		const valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return valid.test(String(email).toLowerCase());
	}

	static isValidDob(dob) {
		if(dob.length != 10) {
			return false;
		}
        let month = parseInt(dob.substring(0,2));
        let day = parseInt(dob.substring(3,5));
        let year = parseInt(dob.substring(6));
        if(month < 1 || month > 12 || day < 1 || day > 31 || year < 1900 || year > (new Date().getFullYear())) {
            return false;
        }
        return true;
	}

	static isValidPhone(number) {
		if(!number) {
			return false;
		}
		let num = number.replace(/\D/g,'');
		return num.toString().length == 10;
	}

	static isValidTSA(number) {
        if(!number) {
            return;
        }
		return number.toString().length == 9 || number.toString().length == 10;
	}

	static isValidExpiration(expiration) {
        if(!expiration) {
            return;
        }
		const month = parseInt(expiration.substring(0,2));
		if(month > 12) {
			return false;
		}
		const current_year = (new Date()).getFullYear();
        const year = parseInt(expiration.substring(3));
		if(year < current_year) {
			return false;
		}
		return /^[0-9]{2}[/]{1}[0-9]{4}?$/.test(String(expiration));
	}

	static isValidCard(number, type) {
        if(!number || !type) {
            return;
        }

		const length = number.toString().length;
		const first_digit = number.toString()[0];

	    // Check number of digits
	    // Visa 16 digits and starts with 4
	    if((type == "VI" || type == "Visa") && (length != 16 || first_digit != '4')) {
        	return false;
	    }
	    // Mastercard accepts 16 digits and starts with 5 or 2
	    else if ((type == "MC" || type == "Mastercard") && (length != 16 || first_digit != '5')) {
	        	return false;
	    }
	    // Discover accepts 16-19 digits and starts with 6
	    else if ((type == "DC" || type == "Discover") && ((length < 16 && length > 19) || first_digit != "6")) {
	        	return false;
	    }
	    // American Express accepts 15 digits and starts with 3
	    else if ((type == "AX" || type == "American Express") && (length != 15 || first_digit != '3')) {
	        	return false;
	    }
	    // Check for valid number mathematically
	    else {
	        // accept only digits, dashes or spaces
	        if (/[^0-9-\s]+/.test(number)) return false;
	 
	        // The Luhn Algorithm.
	        var nCheck = 0, nDigit = 0, bEven = false;
	        number = number.toString().replace(/\D/g, "");
	 
	        for (var n = number.length - 1; n >= 0; n--) {
	            var cDigit = number.charAt(n),
	                  nDigit = parseInt(cDigit, 10);
	 
	            if (bEven) {
	                if ((nDigit *= 2) > 9) nDigit -= 9;
	            }
	 
	            nCheck += nDigit;
	            bEven = !bEven;
	        }
	 
	        return (nCheck % 10) == 0;
	    }
	}

	static isValidSecurityCode(code, type) {
        if(!code || !type) {
            return;
        }
		const is_num = /^\d+$/.test(code);

		let len;
		if(type == "American Express" || type == "AX") {
			len = 4;
		} else {
			len = 3;
		}
		const is_len = code.toString().length == len;

		return is_num && is_len;
	}

	static isValidZipcode(zipcode) {
        if(!zipcode) {
            return;
        }
		return /^\d+$/.test(zipcode) && zipcode.toString().length == 5;
    }
    
    static isValidKnownNumber(knownNumber) {
        // Empty is ok
        if(!knownNumber) {
            return true;
        }
        return (
            (knownNumber.length == 9 || knownNumber.length == 10) &&
            this.isAlphaNumeric(knownNumber));
    }

    static isValidRedressNumber(redressNumber) {
        // Empty is ok
        if(!redressNumber) {
            return true;
        }
        return (
            redressNumber.length == 7 &&
            this.isAlphaNumeric(redressNumber)
        );
    }

    static isValidRewardsNumber(rewardsNumber) {
        // Empty is ok
        if(!rewardsNumber) {
            return true;
        }
        return (
            rewardsNumber.length >= 6 &&
            rewardsNumber.length <= 11 &&
            this.isAlphaNumeric(rewardsNumber)
        );
    }
}

export default Validator;