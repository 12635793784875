<template>
    
    <form v-on:submit.prevent id="password-form">
        <div class="flex my-3">
            <div class="block relative w-full">
                <input type="hidden" name='p' value="<?php echo $app->constants->user['PAGE_REQUEST'] ?>">
                <input 
                    type="password" 
                    name="password" 
                    class="password border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full" 
                    :class="{'input-error': displayError && invalidPassword}" 
                    placeholder="Password"
                    id="pass1" 
                    v-model="password1"
                >
            </div>
        </div>
        <div class="flex my-3">
            <div class="block relative w-full">
                <input 
                    type="password" 
                    name="password-confirm" 
                    class="password border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full" 
                    :class="{'input-error': displayError && invalidPassword2}" 
                    id="pass2"
                    placeholder="Confirm Password" 
                    v-model="password2"
                >
            </div>
        </div>
            <!--<button type="submit" id="login" data-toggle="modal" data-target="#password-modal">Login</button>-->
        <div class="flex">
            <div class="block relative w-full mt-2">
                <input 
                    type="submit"
                    class="hover:bg-purple-300 hover:text-white focus:outline-none bg-purple-500 font-normal fs-12 outline-none px-10 py-3 rounded text-white w-full" 
                    value="Save" 
                    @mouseover="displayError = true"  
                    @click="changePassword"
                >
		
                <div v-if="displayError">
                <p class="text-sm text-red-500 text-center py-1">{{ errorMessage }}</p>
            </div>
            </div>
            
        </div>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                password1: '',
                password2: '',
                // UI Control
                displayError: false
            };
        },
        computed: {
            validLength() {
                return this.password1.length >= 8;
            },
            containsLowercase() {
                return this.password1.toUpperCase() != this.password1;
            },
            containsUppercase() {
                return this.password1.toLowerCase() != this.password1;
            },
            containsNumber() {
                return /\d/.test(this.password1);
            },
            containsSpecialChar() {
                const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                return specialChars.test(this.password1);
            },
            errorMessage() {
                if(!this.validLength) {
                    return 'Must be at least 8 characters';
                } else if(!this.containsLowercase) {
                    return 'Must contain a lowercase letter';
                } else if(!this.containsUppercase) {
                    return 'Must contain an uppercase letter';
                } else if(!this.containsNumber) {
                    return 'Must contain a number';
                } else if(!this.containsSpecialChar) {
                    return 'Must contain a special character';
                } else if(this.invalidPassword2) {
		    return 'Passwords do not match';
		}
                return '';
            },
            invalidPassword() {
                return !this.validLength || !this.containsLowercase || !this.containsUppercase || !this.containsNumber || !this.containsSpecialChar;
            },
            invalidPassword2() {
                return this.password1 != this.password2;
            }
        },
	methods: {
		changePassword() {
			if(this.invalidPassword || this.invalidPassword2) {
				return;
			}
			this.$emit('change-password', this.password1);	
		}
	}
    }
</script>
