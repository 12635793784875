<template>
    <div>
        {{text}}
    </div>
</template>

<script>

import axios    from 'axios';
import qs       from 'qs';

import Cookie   from '@source-classes/Cookie';

export default {
    data() {
        return {
            text: 'Approving trips...'
        }
    },
    created() {
        
        axios.post(
            axios.post(
                system_output.user_session_data.api_hostname + 'UniversalRecord/auto_approve/', 
                qs.stringify( { 
                    token: Cookie.getCookie('otapi_token'),
                    hostname: system_output.framework_info.frameworks.api.return.site_hostname,
                    user_ip: system_output.user_session_data.user_ip
                } ),
                { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
            )
            .then(res => {
                console.log("res:", res);
                if(res.data.status && res.data.status.isSuccessful) {
                    this.text = "Trips have been auto-approved";
                } else {
                    this.text = "ERROR: There was a problem while attempting to auto-approve trips.";
                }
                
            })
            .catch(e => {
                console.log("Error:", e);
                this.text = "ERROR: There was a problem while attempting to auto-approve trips.";
            })
        );
    }
}

</script>