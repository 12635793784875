<template>
    <h1>This page does not exist.</h1>
</template>

<script>
    export default {
        created() {
            this.$router.push('/')
        }
    }
</script>