<template>
    <form v-on:submit.prevent id="affiliate-signup-page">
        <article class="text-left">
            <div class="form-container pt-10">
                <div class="flex flex-wrap w-full mb-5">
                    <div class="lg:w-1/2 w-full">
                        <h1 class="main-title">Tempotrip
                            Account Sign-up Form</h1>
                        <div class="purple-line-thick h-1 w-20 bg-indigo-500 rounded"></div>
                    </div>
                    <p class="lg:w-1/2 my-3 w-full leading-relaxed text-base mt-5 lg:mt-0">Please fill out the
                        required fields below and submit, then an account notification email will be sent to
                        complete your registration and begin to customize your tempotrip account.
                    </p>
                    <p class=" w-full leading-relaxed mt-10 font-semibold">PLEASE ONLY FILL THIS OUT
                        WHEN YOU HAVE ALL THE INFORMATION READY TO COMPLETE THIS FORM.
                    </p>
                </div>
            </div>
            <div class="form-container pt-0">
                <div class="lg:w-1/2 w-full mb-4">
                    <h1 class="section-title text-lg font-medium title-font mb-2 text-gray-900">Basic Info</h1>
                    <div class="purple-line-thin"></div>
                </div>
                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2">
                        <input 
                            type="text" 
                            name="co_name" 
                            required 
                            placeholder="Company Name"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            :class="{'input-error': invalidCompanyName}" 
                            v-model="affiliate.co_name"
                        >
                    </div>
                </div>
                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="address" 
                            required 
                            placeholder="Street Address"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            :class="{'input-error': invalidAddress}" 
                            v-model="affiliate.address"
                        >
                    </div>
                </div>
                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="address2" 
                            placeholder="Address Line 2"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            v-model="affiliate.address2">
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2"></p>
                </div>

                <div class="flex flex-wrap w-full mb-5">
                    <div class="flex flex-col sm:flex-row sm:justify-between flex-gap-1 w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="city" 
                            required 
                            placeholder="City"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            :class="{'input-error': invalidCity}" 
                            v-model="affiliate.city"
                        >
                        <select 
                            id="state" 
                            name="state"
                            class="lg:mx-2 border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            :class="{'input-error': invalidState}" 
                            v-model="affiliate.state"
                        >
                            <option v-for="state in states" :key="state.code" :value="state.code">
                                {{state.name}}
                            </option>
                        </select>
                        <input 
                            type="text" 
                            name="zip" 
                            required 
                            placeholder="Zipcode"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            :class="{'input-error': invalidZip}" 
                            v-model="affiliate.zip"
                        >
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2"></p>
                </div>
                <!-- COMPANY MAIN CONTACT INFORMATION-->
                <div class="lg:w-1/2 w-full mb-2">
                    <h1 class="section-title">Main Contact Info</h1>

                    <div class="purple-line-thin"></div>
                </div>
                <p class="text-gray-600 text-xs italic mb-3"><span class="text-boldest">Best practice</span> is
                    to create the first contact as something like First name: "Companyname", Last Name: "Admin",
                    Email: "companyname@tempotrip.com", Phone: anything you want<br>This person will be sites
                    master admin. After account is created you can go in and add their main TRUE main contact as
                    a admin. </p>
                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="f_name" 
                            required 
                            placeholder="Contact First Name"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            :class="{'input-error': invalidFirstName}" 
                            v-model="affiliate.f_name"
                        >
                    </div>
                </div>
                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="l_name" 
                            required 
                            placeholder="Contact Last Name"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            :class="{'input-error': invalidLastName}" 
                            v-model="affiliate.l_name"
                        >
                    </div>
                </div>

                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="tel" 
                            name="phone" 
                            maxlength="14"
                            pattern=".{10,14}" 
                            required
                            placeholder="Contact Phone Number"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            :class="{'input-error': invalidPhone}" 
                            v-model="affiliate.phone"
                            @input="formatPhone"
                        >
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2"></p>
                </div>

                <div class="flex flex-wrap w-full mb-5">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="email" 
                            name="email" 
                            required 
                            placeholder="Contact Email Address"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            :class="{'input-error': invalidEmail}" 
                            v-model="affiliate.email"
                        >
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2"></p>
                </div>

                <!-- COMPANY TRAVEL INFORMATION-->
                <div class="lg:w-1/2 w-full mb-4">
                    <h1 class="section-title">Travel Setup Info</h1>
                    <div class="purple-line-thin"></div>
                </div>
                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="air_service_fee" 
                            placeholder="Air service fee"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            v-model="affiliate.air_service_fee"
                        >
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2"> Input AIR service fee or leave blank to use
                        default</p>
                </div>

                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="car_service_fee" 
                            placeholder="Car Service Fee"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            v-model="affiliate.car_service_fee"
                        >
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2"> Input CAR service fee or leave blank to use
                        default</p>
                </div>

                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="air_corporate_rewards" 
                            placeholder="Air Rewards Number"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            v-model="affiliate.air_corporate_rewards"
                        >
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2">IMPORTANT: CODE:NUMBER - Format Example:
                        AA:ITCB12345,UA:ITDE13442t3</p>
                </div>
                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="car_corporate_rewards" 
                            placeholder="Car Rewards Number"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            v-model="affiliate.car_corporate_rewards"
                        >
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2">IMPORTANT CODE:NUMBER Format - Example:
                        ET:ITCB12345,ZL:ITDE13442t3</p>
                </div>

                <div class="flex flex-wrap w-full mb-2">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="existing_bar" 
                            placeholder="BAR"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            v-model="affiliate.existing_bar"
                        >
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2">Input existing BAR or leave blank</p>
                </div>

                <div class="flex flex-wrap w-full mb-5">
                    <div class="flex flex-col sm:flex-row w-full mt-2 lg:mt-0">
                        <input 
                            type="text" 
                            name="itinerary_id" 
                            placeholder="Custom Itinerary ID"
                            class="border border-gray-300 focus:border-purple-300 focus:outline-none leading-normal px-3 py-3 rounded text-base text-black w-full"
                            v-model="affiliate.itinerary_id"
                        >
                    </div>
                    <p class="text-gray-600 text-xs italic mt-2">Get from mid-office configuration or leave blank</p>
                </div>
                <div class="p-10 mt-5 border-t my-5">
                    <div class="flex flex-wrap -mx-2">
                        <div class="w-1/2 lg:w-auto px-2 ml-auto">
                            <button id="submit"
                                class="hover:bg-purple-300 hover:text-white bg-purple-500 font-normal fs-12 outline-none px-10 py-3 rounded text-white"
                                type="submit" 
                                name="submit" 
                                value="affiliate-signup-form"
                                @click="$emit('save-affiliate', affiliate)" 
                                @mouseover="displayError = true;"
                                :disabled="invalidAffiliate"
                            >
                                Sign up
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </form>
</template>

<script>

    import Validator        from '@source-classes/Validator';

    import states           from '@source-data/states';

    export default {
        data() {
            return {
                // Form elements
                affiliate: {
                    co_name:                    '',
                    address:                    '',
                    address2:                   '',
                    city:                       '',
                    state:                      '',
                    zip:                        '',
                    f_name:                     '',
                    l_name:                     '',
                    phone:                      '',
                    email:                      '',
                    air_service_fee:            '',
                    car_service_fee:            '',
                    air_corporate_rewards:      '',
                    car_corporate_rewards:      '',
                    existing_bar:               '',
                    itinerary_id:               '',
                },
                displayError:                  false,
                states  
            };
        },
        computed: {
            invalidCompanyName() {
                return this.displayError && this.affiliate.co_name == '';
            },
            invalidAddress() {
                return this.displayError && this.affiliate.address == '';
            },
            invalidCity() {
                return this.displayError && this.affiliate.city == '';
            },
            invalidState() {
                return this.displayError && this.affiliate.state == '';
            },
            invalidZip() {
                return this.displayError && this.affiliate.zip == '';
            },
            invalidFirstName() {
                return this.displayError && this.affiliate.f_name == '';
            },
            invalidLastName() {
                return this.displayError && this.affiliate.l_name == '';
            },
            invalidPhone() {
                return this.displayError && this.affiliate.phone == '';
            },
            invalidEmail() {
                return this.displayError && !Validator.isValidEmail(this.affiliate.email);
            },
            invalidAffiliate() {
                return this.invalidCompanyName ||
                        this.invalidAddress || 
                        this.invalidCity ||
                        this.invalidState ||
                        this.invalidZip ||
                        this.invalidFirstName ||
                        this.invalidLastName ||
                        this.invalidPhone ||
                        this.invalidEmail;
            }
        },
        methods:{
            formatPhone() {
            var x = this.affiliate.phone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.affiliate.phone = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
            },
        }
    }
</script>
<style scoped>
.purple-line-thick{
color: #2c3e50;
line-height: 1.25;
text-align: left;
box-sizing: inherit;
border: 0 solid #cecece;
border-radius: .25rem;
height: .25rem;
width: 5rem;
--bg-opacity: 1;
background-color: rgba(102,126,234,var(--bg-opacity));
}
.purple-line-thin {
color: #2c3e50;
border-radius: .25rem;
height: 1px;
width: 5rem;
--bg-opacity: 1;
background-color: rgba(102,126,234,var(--bg-opacity));
}
.main-title{
font-weight: 500;
margin-bottom: .5rem;
color: #1a202c;
font-size: 1.875rem;
}
.section-title{
font-weight: 500;
margin-bottom: .5rem;
color: #1a202c;
font-size: 1.125rem;
}
.form-container{
width: 100%;
max-width: 1065px;
margin-left: auto;
margin-right: auto;
padding-left: 1.25rem;
padding-right: 1.25rem;
padding-bottom: 0;
padding-top: 0;
}
.pt-10 {
    padding-top: 2.5rem!important;
}
.pt-0{padding-top:0!important}
</style>